import React from 'react';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';
import { Inner, WrapConstrained } from 'styles/structure';
import colors from "styles/colors";
import Recording from '../../../vectors/recording.svg';
import Virtual from '../../../vectors/virtual.svg';
import InPerson from '../../../vectors/in-person.svg';
import Diamond from '../../../vectors/diamond.svg';

const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 4rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 8rem;
  }
`;

const HeroInner = styled(Inner)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {}
`;

const HeroImage = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  margin-bottom: 3.2rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    height: 31.2rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 5.6rem;
  }
`;

const HeroContent = styled(WrapConstrained)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroTitle = styled.h1`
  font-size: 2.8rem;
  line-height: 1.22;
  text-align: center;
  margin-bottom: 1.6rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    font-size: 4.4rem;
    line-height: 1.18;
  }
`;

const HeroMeta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    flex-direction: row;
    flex-wrap: wrap;  
  }
`;

const HeroMetaItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2.4rem;
  margin-bottom: 0.6rem;
  
  > * + * {
    margin-left: 0.8rem;
  }
  
  &:last-of-type {
    margin-right: 0;
  }
  
  svg {
    display: block;
    fill: ${colors.purple800};
  }
  
  span {
    display: flex;
    color: ${colors.purple800};
    font-size: 1.6rem;
    font-weight: 600;
    
    &:first-of-type {
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    
    &:last-of-type {
      svg {
        display: inline-block;
        width: 1.3rem;
        height: 1.6rem;
        fill: ${colors.grey200};

        & + * {
          margin-left: 0.2rem;
        }
      }
    }
  }
  
  .event-participation--One,
  .event-participation--Two,
  .event-participation--Three,
  .event-participation--Four,
  .event-participation--Five {
    svg:nth-child(1) {
      fill: ${colors.purple800};
    }
  }
  
  .event-participation--Two,
  .event-participation--Three,
  .event-participation--Four,
  .event-participation--Five {
    svg:nth-child(2) {
      fill: ${colors.purple800};
    }
  }
  
  .event-participation--Three,
  .event-participation--Four,
  .event-participation--Five {
    svg:nth-child(3) {
      fill: ${colors.purple800};
    }
  }
  
  .event-participation--Four,
  .event-participation--Five {
    svg:nth-child(4) {
      fill: ${colors.purple800};
    }
  }
  
  .event-participation--Five {
    svg:nth-child(5) {
      fill: ${colors.purple800};
    }
  }

  @media (min-width: ${dimensions.tabletPortraitUp}px) {
    span {
      &:last-of-type {
        svg {
          & + * {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
`;

const EventHero = ({ title, recording, format, participation, image }) => {
  return (
    <HeroContainer>
      <HeroInner>
        {image && (
          <HeroImage>
            <img src={image.url} alt={image.alt} />
          </HeroImage>
        )}

        <HeroContent>
          {title && <HeroTitle>{title.text}</HeroTitle>}

          <HeroMeta>
            {recording && (
              <HeroMetaItem>
                  <span>
                    <Recording/>
                  </span>
                <span>Recording</span>
              </HeroMetaItem>
            )}

            <HeroMetaItem>
                <span>
                  {format === "Virtual" ? <Virtual/> : <InPerson/>}
                </span>
              <span>{format}</span>
            </HeroMetaItem>

            <HeroMetaItem>
              <span>Participation</span>
              <span className={`event-participation--${participation}`}>
                  <Diamond/>
                  <Diamond/>
                  <Diamond/>
                  <Diamond/>
                  <Diamond/>
                </span>
            </HeroMetaItem>
          </HeroMeta>
        </HeroContent>
      </HeroInner>
    </HeroContainer>
  );
};

export default EventHero;
