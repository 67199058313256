/* eslint-disable limited-exports-page-templates */

import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled'
import Moment from 'react-moment';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner, Wrap } from 'styles/structure';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import Button from 'components/_ui/Button';
import RichText from 'components/RichText';
import EventHero from 'components/_page/event/Hero';
import SliceZone from 'components/SliceZone';
import { withPreview } from 'gatsby-source-prismic';

const PageContent = styled(Wrap)`
  margin-bottom: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    flex-direction: row;
    margin-bottom: 14.4rem;
  }
`;

const PageColumn = styled.div`
  &:first-of-type {
    order: 2;
  }
  
  &:last-of-type {
    order: 1;
    background-color: ${colors.grey100};
    padding: 1.6rem;
    margin-bottom: 4rem;
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    &:first-of-type {
      flex: 1 1 100%;
      order: 1;
    }

    &:last-of-type {
      position: sticky;
      top: 12.8rem;
      flex: 0 0 29.5rem;
      order: 2;
      margin-left: 8rem;
      background-color: unset;
      padding: 0;
      margin-bottom: 0;
    }  
  }
`;

const EventIntroduction = styled.div`
  margin-bottom: 2.4rem;
  
  p {
    font-size: 2.2rem;
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-bottom: 4rem;
  }
`;

const EventDetails = styled.div`
  > div + * {
    margin-top: 1.6rem;
  }
`;

const EventDate = styled.span`
  display: block;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 0.8rem;
  
  > span {
    display: block;
  }
`;

const EventTime = styled.span`
  display: block;
  font-size: 1.8rem;
  
  p {
    font-size: 1.8rem;
  }
`;

const EventLocation = styled.span`
  display: block;
  font-size: 2.4rem;
  font-weight: bold;
`;

const EventLocationDetailed = styled.span`
  display: block;
  font-size: 1.8rem;

  p {
    font-size: 1.8rem;
  }
`;

const EventRegistration = styled.div`
  position: relative;
  margin-top: 2.4rem;
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
      margin-top: 4.8rem;
  }
`;

const EventDisclaimer = styled.p`
  color: ${colors.purple800};
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.32;
  margin-top: 1.6rem;

  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    font-size: 1.8rem;
  }
`

export const Event = ({ data }) => {
  const page = data.prismicEvent.data;

  if(!page) {
    return null;
  }

  // A published end date that is cleared still retains a blank January 1st date on re-publish
  // Check that if there IS an end date, that it's not January 1st, and if so, that the start date is not in December
  let noEndDate = !page.end_date || (page.end_date && page.end_date.slice(-5) === "01-01" && page.date.slice(5,7) !== "12");

  return (
    <Layout>
      <Seo
        title={page.social_title || page.title.text || 'Simple Page'}
        description={page.social_description}
        image={page.social_image}
        unlisted={page.unlisted}
      />

      <EventHero
        title={page.title}
        image={page.feature_image}
        recording={page.recording}
        format={page.format}
        participation={page.participation}
      />

      <Inner>
        <PageContent>
          <PageColumn>
            {page.introduction && (
              <EventIntroduction><RichText render={page.introduction.raw} /></EventIntroduction>
            )}

            {page.body && (
              <SliceZone
                body={page.body}
              />
            )}
          </PageColumn>

          <PageColumn>
            <EventDetails>
              <div>
                {page.date && (
                  <EventDate>
                    {page.date && <span><Moment format="MMMM Do, YYYY" date={page.date} />{page.end_date && <span> - </span>}</span>}
                    {(page.date && !noEndDate) && <span><Moment format="MMMM Do, YYYY" date={page.end_date} /></span>}
                  </EventDate>
                )}

                {page.time && <EventTime>{page.time}</EventTime>}
                {page.time_detailed && <EventTime><RichText render={page.time_detailed.raw} /></EventTime>}
              </div>

              <div>
                {page.location && <EventLocation>{page.location}</EventLocation>}
                {page.location_detailed && <EventLocationDetailed><RichText render={page.location_detailed.raw} /></EventLocationDetailed>}
              </div>
            </EventDetails>

            {page.registration_url.url && <EventRegistration><Button as="a" href={page.registration_url.url} target="_blank">Register Now</Button></EventRegistration>}

            {page.disclaimer_text && <EventDisclaimer>{page.disclaimer_text}</EventDisclaimer>}
          </PageColumn>
        </PageContent>
      </Inner>
    </Layout>
  );
}

export const query = graphql`
  query getEvent($uid: String!) {
    prismicEvent(uid: {eq: $uid}) {
      uid
      data {
        unlisted
        title {
          text
          raw
        }
        feature_image {
          url
          alt
        }
        introduction {
          raw
        }
        date
        end_date
        time
        time_detailed {
          raw
          text
        }
        location
        location_detailed {
          raw
          text
        }
        registration_url {
          url
        }
        recording
        format
        participation
        disclaimer_text
        tout_image {
          url
          alt
        }
        tout_text {
          raw
          text
        }
        body {
          ... on PrismicEventBodyText {
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicEventBodyBlockImage {
            slice_type
            primary {
              image {
                url
                alt
              }
              caption
              credit
              url
            }
          }
          ... on PrismicEventBodyCustomHtml {
            slice_type
            primary {
              content {
                text
              }
            }
          }
          ... on PrismicEventBodyTrapdoors {
            slice_type
            primary {
              trapdoor_color
            }
            items {
              heading {
                raw
              }
              content {
                raw
                text
              }
              destination
              button_text
              alignment
              hover_image {
                url
                alt
              }
            }
          }
          ... on PrismicEventBodyFaq {
            slice_type
            items {
              question
              answer {
                raw
                text
              }
            }
          }
        }
        social_title
        social_description
        social_image {
          url
        }
      }
    }
  }
`;

export default withPreview(Event);